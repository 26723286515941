<template>
  <div class="banner-section wf-section p-5 md:p-50">
    <div
      style="
        background-color: rgba(0, 0, 0, 0.85);
        -webkit-backdrop-filter: blur(1px);
        backdrop-filter: blur(1px);
        max-width: 800px;
        color: white !important;
      "
      class="container mx-auto mt-10 p-5 rounded-3xl"
    >
      <h1 class="text-3xl font-semibold mb-1 text-center">
        Faber Verified Rate Calculator
      </h1>
      <p class="text-center mb-4 italic text-sm">
        For Sustainability Linked Loans in Vietnam
      </p>
      <p class="text-center">
        Provide details of your project to find the best SLL financing via Faber
      </p>

      <div class="grid grid-cols-3 gap-2 mb-8 mt-5">
        <label for="projectSize">Project Size:</label>
        <span class="r"
          ><strong>{{ projectSize }}</strong> kWp</span
        >
        <input
          v-model.number="projectSize"
          type="range"
          min="200"
          max="5000"
          step="10"
          class="slider"
        />

        <label for="waterSaved">Water Waste Reduced:</label>
        <span class="r"
          ><strong>{{ waterSaved * 100 }}</strong> %</span
        >
        <input
          v-model.number="waterSaved"
          type="range"
          min="0"
          max="0.5"
          step="0.1"
          class="slider"
        />

        <label for="wasteSaved">Solid Waste Reduced:</label>
        <span class="r"
          ><strong>{{ wasteSaved * 100 }}</strong> %</span
        >
        <input
          v-model.number="wasteSaved"
          type="range"
          min="0"
          max="0.5"
          step="0.1"
          class="slider"
        />

        <label for="ltv">LTV:</label>
        <span class="r"
          ><strong>{{ ltv * 100 }}</strong> %</span
        >
        <input
          v-model.number="ltv"
          type="range"
          min="0.5"
          max="1"
          step="0.1"
          class="slider"
        />
      </div>

      <hr style="margin: 20px" />

      <div class="grid grid-cols-2 gap-4 mb-8">
        <label>EPC Value: </label><span> {{ epcFormatted }} </span>
        <label>Loan Size: </label><span> {{ loanSizeFormatted }} </span>
        <label>Loan Tenor: </label><span> {{ tenor }} months</span>
        <label>Market Rate: </label><span> 9.5 - 13% </span>
        <label>Faber Verified Rate: </label>
        <span>
          {{ faberRateMinFormatted }} - {{ faberRateMaxFormatted }}%
        </span>
        <label>You save with Faber Verified: </label>
        <span> {{ faberSavingsFormatted }} </span>
        <label>Monthly CO2 Saved (tons): </label>
        <span> {{ monthlyEmissionsSavedFormatted }} </span>
      </div>

      <div class="flex justify-center">
        <button
          v-if="faberSavings > 0"
          class="navigation-button w-button font-bold py-2 px-4 rounded"
          @click="$emit('show-faber-rate-details')"
        >
          Get in touch
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// project size in kWp to EPC value in USD. 1MWp = 665k USD
const POWER_TO_EPC = 665;

export default {
  name: "LoanCalculatorBorrower",
  created() {
    const urlParams = new URLSearchParams(window.location.search);

    this.projectSize = this.clip(
      parseInt(urlParams.get("projectSize") || 500),
      200,
      5000
    );
    this.waterSaved = this.clip(
      parseFloat(urlParams.get("waterSaved") || 0),
      0,
      0.5
    );
    this.wasteSaved = this.clip(
      parseFloat(urlParams.get("wasteSaved") || 0),
      0,
      0.5
    );
    this.ltv = this.clip(parseFloat(urlParams.get("ltv") || 0.7), 0.5, 1);
  },

  props: {},
  data() {
    return {
      // inputs
      projectSize: 500,
      waterSaved: 0,
      wasteSaved: 0,
      ltv: 0.5,

      // assumptions
      tenor: 11,
      inflation: 6.04,
      poundsPerTon: 2000,
      interestRate: 10,
      hoursPerYear: 1460,
      emissionsSavedPerKwh: 0.846,
    };
  },
  computed: {
    // Calculations
    sizeDiscount() {
      return this.clip(Math.floor(this.projectSize / 100) * 0.0005, 0, 0.025);
    },
    waterDiscount() {
      return this.clip(Math.floor(this.waterSaved * 10) * 0.001, 0, 0.005);
    },
    wasteDiscount() {
      return this.clip(Math.floor(this.wasteSaved * 10) * 0.001, 0, 0.005);
    },
    epc() {
      return this.projectSize * POWER_TO_EPC;
    },
    loanSize() {
      return this.epc * this.ltv;
    },
    monthlyEmissionsSaved() {
      return (
        (this.monthlyOutput * this.emissionsSavedPerKwh) / this.poundsPerTon
      );
    },
    monthlyOutput() {
      return (
        (this.projectSize * this.emissionsSavedPerKwh * this.hoursPerYear) / 12
      );
    },
    faberDiscount() {
      return this.sizeDiscount + this.wasteDiscount + this.waterDiscount;
    },
    faberSavings() {
      return this.loanSize * this.faberDiscount;
    },

    // Formatting
    epcFormatted() {
      return this.formatCurrency(this.epc);
    },
    loanSizeFormatted() {
      return this.formatCurrency(this.loanSize);
    },
    faberSavingsFormatted() {
      return this.formatCurrency(this.faberSavings);
    },

    faberRateMinFormatted() {
      return this.round(9.5 - this.faberDiscount * 100, 1);
    },
    faberRateMaxFormatted() {
      return this.round(13 - this.faberDiscount * 100, 1);
    },
    monthlyEmissionsSavedFormatted() {
      return this.round(this.monthlyEmissionsSaved);
    },
  },
  methods: {
    formatCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(value);
    },
    round(value, precision) {
      if (typeof value !== "number") {
        return value;
      }
      const formatter = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: precision || 0,
      });
      return formatter.format(value);
    },
    clip(value, min, max) {
      return Math.min(Math.max(value, min), max);
    },
    updateUrl() {
      const url = new URL(window.location.href);
      ["projectSize", "waterSaved", "wasteSaved", "ltv"].forEach((key) => {
        url.searchParams.set(key, this[key]);
      });

      window.history.replaceState({}, "", url.toString());
    },
  },
  watch: {
    projectSize() {
      this.updateUrl();
    },
    waterSaved() {
      this.updateUrl();
    },
    wasteSaved() {
      this.updateUrl();
    },
    ltv() {
      this.updateUrl();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: white;
}

.grid label,
.grid span.r {
  text-align: right;
}

input,
select {
  color: black;
}
</style>
