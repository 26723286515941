<template>
  <LoanCalculatorBorrower @show-faber-rate-details="openModal" />

  <div
    class="fixed inset-0 z-50 flex items-center justify-center overflow-auto text-center max-w-lg mx-auto"
    v-if="showModal"
    v-cloak
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75"
      @click="closeModal"
    ></div>
    <div class="relative w-auto mx-auto my-6 bg-white rounded shadow-lg z-10">
      <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
        <h3 class="text-lg leading-6 text-gray-900">
          Get best financing rates with Faber Verified
        </h3>
        <div class="mt-2">
          <p class="text-gray-500">
            To connect with Faber IoT for data collection and get access to our
            network of lenders offering the Faber Verified Rate™, get in touch
            with our team.
          </p>
          <p class="mg-top-30 text-lg">
            <a href="mailto:contact@faber.capital">
              <img
                src="https://uploads-ssl.webflow.com/6417ce7c507092103ab552b7/6417ce7c507092b391b55349_mail-icon-yellow.svg"
                loading="lazy"
                alt="Mail Icon"
                height="30"
                style="display: inline"
              />
              contact@faber.capital</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoanCalculatorBorrower from "./components/LoanCalculatorBorrower.vue";

export default {
  name: "App",
  components: {
    LoanCalculatorBorrower,
  },
  props: {},
  data() {
    return {
      // ui
      showModal: false,
      email: "",
    };
  },
  computed: {},
  methods: {
    openModal: function () {
      this.showModal = true;
    },
    closeModal: function () {
      this.showModal = false;
    },
    validateEmail(email) {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(String(email).toLowerCase());
    },
  },
};
</script>

<style></style>
